import { inject } from '@angular/core';
import { type ActivatedRouteSnapshot, type CanActivateFn, Router } from '@angular/router';
import { FlightOrchestratorFlagsService } from '@shared/services/flight-orchestrator-flags/flight-orchestrator-flags.service';
import { catchError, map, of } from 'rxjs';

export const flightOrchestratorFlagsGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const flightOrchestratorFlagsService = inject(FlightOrchestratorFlagsService);
  const router = inject(Router);
  const redirectPath = router.parseUrl(route.data['redirectTo'] ?? '');
  return flightOrchestratorFlagsService.load().pipe(
    map(() => {
      if (route.data['featureType'] === 'aircraft') {
        return flightOrchestratorFlagsService.aircraftFeaturesUnlocked();
      } else if (route.data['featureType'] === 'flights') {
        return flightOrchestratorFlagsService.flightsFeaturesUnlocked();
      }
      throw new Error('invalid featureType');
    }),
    map((unlocked) => (unlocked ? true : redirectPath)),
    catchError(() => of(redirectPath))
  );
};
